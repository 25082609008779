import styled from '@emotion/styled';

export const StyledPropertyRates = styled.div`
  
`;

export const StyledPropertyRatesSeasonValue = styled.span`
  
`;

export const StyledPropertyRatesSeasonName = styled.div`
  font-weight:${props => props.theme['typography-font-weight-thick']};
`;

export const StyledPropertyRatesSeasonDates = styled.div`
  
`;

export const StyledPropertyRatesPriceValue = styled.span`
  
`;

export const StyledPropertyRatesMinStayValue = styled.span`
  
`;
