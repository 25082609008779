import { chakra } from '@chakra-ui/react';
import Info from '@rentivo/gatsby-core/src/components/ui/Info';

export const StyledPropertyFeatures = chakra('div', {
  baseStyle: {

  }
});

export const StyledPropertyFeatureSet = chakra('div', {
  baseStyle: {
    mb: 4,
    pb: 4,
    borderBottom: '1px solid',
    borderBottomColor: 'gray.100',
    '&:last-child': {
      mb: 0,
      pb: 0,
      borderBottom: 0
    }
  }
});

export const StyledPropertyFeatureSetFeature = chakra('div', {
  baseStyle: {

  }
});

export const StyledPropertyFeatureSetFeatureInfo = chakra(Info, {
  baseStyle: {
    ml: '5px'
  }
});
