import React from 'react';
import { useIntl } from 'react-intl';
import messages, { messagesObject } from '@rentivo/gatsby-core/src/components/property/PropertyHeader/messages';
import { messagesToFormattedObject } from '@rentivo/gatsby-core/src/utils/messages';
import { getMessageString } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/utils';
import { Heading, Text, chakra, Flex } from '@chakra-ui/react';
import PropertyAddress from '@rentivo/gatsby-core/src/components/property/PropertyAddress';
import PropertyListingMeta from '@rentivo/gatsby-core/src/components/property/PropertyListingMeta';
import PropertyTagsShort from '@rentivo/gatsby-core/src/components/property/PropertyTagsShort';

const PropertyHeaderComponent = ({wrapper: Wrapper, className, extraVars, image, siteVariables, showSubtitle, styleProps}) => {
  const { formatMessage } = useIntl();
  const type = extraVars.type;
  extraVars.type = getMessageString(extraVars.type, formatMessage);
  const variables = { ...siteVariables, ...extraVars };
  const content = messagesToFormattedObject(formatMessage, messages, messagesObject, variables);
  const pageTitle = content.pageTitle;
  const pageSubtitle = extraVars.bedrooms === 0 && type === 'LISTING_TYPE_STUDIO' ? content.pageSubtitleZeroBedroom : content.pageSubtitle; // TODO: Swap for pageSubtitleZeroBedroom when 0 bedrooms & studio...

  return (
    <Wrapper>
      <chakra.div className={className} {...styleProps}>
        <PropertyAddress styleProps={{color: 'textLight', mb: 1}}/>
        <Heading mt={0} mb={(showSubtitle && pageSubtitle) ? 1 : 3} as="h1">{pageTitle}</Heading>
        {(showSubtitle && pageSubtitle) && <Text mb={3} variant="light">{pageSubtitle}</Text>}
        <Flex
          justify="space-between"
          wrap="wrap"
        >
          <PropertyListingMeta showLabels={true} showDetail={true}/>
          <PropertyTagsShort popoverPlacement="bottom-end"/>
        </Flex>
      </chakra.div>
    </Wrapper>
  );
};

export default PropertyHeaderComponent;
