import React from 'react';
import { Heading } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyRooms/messages';
import { BATHROOM } from '@rentivo/gatsby-core/src/constants/lycanConstants';
import { faBath } from '@fortawesome/pro-regular-svg-icons/faBath';
import { faBed } from '@fortawesome/pro-regular-svg-icons/faBed';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faHandHolding } from '@fortawesome/pro-regular-svg-icons/faHandHolding';
import { faHotTub } from '@fortawesome/pro-regular-svg-icons/faHotTub';
import { faShower } from '@fortawesome/pro-regular-svg-icons/faShower';
import { faToilet } from '@fortawesome/pro-regular-svg-icons/faToilet';
import LycanFormattedMessage from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle';
import { lycanizeString } from '@rentivo/gatsby-core/src/utils/strings';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';

const iconMap = {
  BEDROOM: faBed,
  SINGLE: faBed,
  SINGLE_BUNK: faBed,
  SINGLE_ZIP: faBed,
  DOUBLE_BUNK: faBed,
  DOUBLE_SINGLE_BUNK: faBed,
  QUEEN_SINGLE_BUNK: faBed,
  TRIPLE_BUNK: faBed,
  KING: faBed,
  KING_SUPER: faBed,
  QUEEN: faBed,
  SOFA_QUEEN: faBed,
  SOFA_DOUBLE: faBed,
  SOFA_SINGLE: faBed,
  FOLDING: faBed,
  OTHER: faBed,
  INFLATABLE: faBed,
  WATER: faBed,
  HOT_TUB: faHotTub,
  BATH: faBath,
  WHIRLPOOL: faBath,
  SHOWER: faShower,
  WASH_BASIN: faHandHolding,
  BIDET: faShower,
  TOILET: faToilet
};

const getIcon = (type) => {
  if(iconMap[type]) return iconMap[type];
  return faCheck;
};

const PropertyRoomsComponent = ({wrapper: Wrapper, rooms, showTitle, showTotal, totalMessage, title, type, className, styleProps = {}}) => {
  if(!rooms || !rooms.length) return null;
  return (
    <Wrapper>
      <Box mb={-2} className={className} {...styleProps}>
        {showTitle && <PropertyComponentTitle>{title ? title : <>{type === BATHROOM ? <FormattedMessage {...messages.bathroomsTitle}/> : <FormattedMessage {...messages.bedroomsTitle}/>}</>}</PropertyComponentTitle>}
        {showTotal && <Text mt={-2} as="div" color="textLight" fontWeight="medium" mb={3}>{totalMessage}</Text>}
        <Flex mb={2} wrap="wrap">
          {rooms.map((r, i) => (
            <Box key={r.name + r.type + i} className="room" w={{base: '50%', lg: '33.3%'}}>
              <Heading mb={2} as="h4"><LycanFormattedMessage message={lycanizeString(r.name)} fallback={r.name}/></Heading>
              {(r.isSharedSpace) && (
                <Text
                  as="div"
                  fontSize="md"
                  mb={2}
                  mt={0}
                  fontStyle="italic"
                >
                  <FormattedMessage {...messages.sharedSpaceLabel}/>
                </Text>
              )}
              {(r.blueprint && r.blueprint.length) && (
                <Stack spacing={3}>
                  {r.blueprint.map((b, i) => (
                    <Box w="100%" key={b.type + i}>
                      <AwesomeIcon icon={getIcon(b.type)} mr={2} />
                      {b.quantity} x <LycanFormattedMessage message={b.type}/>
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          ))}
        </Flex>
      </Box>
    </Wrapper>
  );
};

export default PropertyRoomsComponent;
