import React, { useState } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/ui/TruncateText/messages';
import { Button } from '@rentivo/gatsby-core/index';
import { Box } from '@chakra-ui/react';

const TruncateTextComponent = ({lines, className, text, showExpand, styleProps = {}}) => {
  if(!text) return null;
  const [isTextLongEnough, setIsTextLongEnough] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleReflow = ({clamped}) => {
    if(!isTextLongEnough && clamped) {
      setIsTextLongEnough(clamped);
    }
  };

  return (
    <Box as="span" className={className} {...styleProps}>
      <HTMLEllipsis
        unsafeHTML={text}
        onReflow={handleReflow}
        maxLine={(isExpanded) ? 5000 : lines}
        ellipsis='...'
        basedOn='words'
      />
      {showExpand && isTextLongEnough && (
        <Box pt={2}>
          <Button size="small" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <FormattedMessage {...messages.showLessLabel}/> : <FormattedMessage {...messages.showMoreLabel}/>}
          </Button>
        </Box>
      )}
    </Box>
  )
};

export default TruncateTextComponent;

/*
<StyledTruncateText className={className}>
      {isExpanded ? <div dangerouslySetInnerHTML={{__html: text}}/> : (
        <HTMLEllipsis
          unsafeHTML={text}
          maxLine={lines}
          ellipsis='...'
          trimRight
          basedOn='words'
        />
      )}
      {showExpand && (
        <StyledTruncateTextButtonWrap>
          <StyledTruncateTextButton size="small" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <FormattedMessage {...messages.showLessLabel}/> : <FormattedMessage {...messages.showMoreLabel}/>}
          </StyledTruncateTextButton>
        </StyledTruncateTextButtonWrap>
      )}
    </StyledTruncateText>
 */
