import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyIncluded from '@rentivo/gatsby-core/src/components/property/PropertyIncluded/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyLocale, selectPropertyTextByTypeByLocaleFromTexts,
  selectPropertyTexts
} from '@rentivo/gatsby-core/src/selectors/property';
import { WHAT_IS_INCLUDED, WHAT_IS_NOT_INCLUDED } from '@rentivo/gatsby-core/src/constants/lycanConstants';

const defaultProps = {
  includedTitle: null,
  notIncludedTitle: null,
  showTitles: true,
  lines: 10,
  wrapper: ({children}) => children
};

const propertySelector = (state, { type }) => createSelector(
  selectPropertyTexts,
  selectPropertyLocale,
  (texts, locale) => {
    type = (type) ? type : defaultProps.type;
    return {
      included: selectPropertyTextByTypeByLocaleFromTexts(texts, WHAT_IS_INCLUDED, locale),
      notIncluded: selectPropertyTextByTypeByLocaleFromTexts(texts, WHAT_IS_NOT_INCLUDED, locale)
    };
  }
)(state);

const enhance = compose(
  withProperty(propertySelector)
);

PropertyIncluded.defaultProps = defaultProps;

export default enhance(PropertyIncluded);
