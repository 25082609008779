import styled from '@emotion/styled';
import { chakra } from '@chakra-ui/react';

export const StyledBlockImageGallery = chakra('div',{
  baseStyle: {
    position: 'relative'
  }
});

export const StyledBlockImageGalleryContainer = chakra('div',{
  baseStyle: {
    overflow: 'hidden'
  }
});

export const StyledBlockImageGalleryStatic = styled.div`
  float: left;
  position: relative;
  margin-right: 4px;
  overflow: hidden;

  //@extend .bg-loader;

  &:after {
    position: absolute;
    content: "";
    top:0;
    left:0;
    transition: background-color ${props => props.theme['animation-duration-base']} ${props => props.theme['ease-in-out']};
    bottom:0;
    right:0;
    background-color: rgba(255, 255, 255, 0);
  }

  &:hover {
    cursor: pointer;
    &:after {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  
  img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }

  &.ig-1 {
    animation: ant-skeleton-loading 1s ease infinite;
    width: calc(50% - 4px);
    padding-bottom: calc(32% + 4px);

    @media(max-width: ${props => props.theme['screen-md']}) {
      width: calc(66.66% - 4px);
      padding-bottom: calc(40% + 4px);
    }
    
    &.three {
       width: calc(66.66% - 4px);
       padding-bottom: calc(40% + 4px);
    }

  }

  &:not(.ig-1) {
    width: calc(25% - 4px);
    margin-bottom: 4px;
    padding-bottom: 16%;

    @media(max-width: ${props => props.theme['screen-md']}) {
      width: calc(33.33% - 4px);
      padding-bottom: 20%;
    }
    
    &.three {
      width: calc(33.33% - 4px);
      padding-bottom: 20%;
    }
  }

  &.ig-4, &.ig-5 {
    
    &.three {
      display:none;
    }
  
    @media(max-width: ${props => props.theme['screen-md']}) {
      display: none;
    }
  }

`;

export const StyledBlockImageGalleryAction = chakra('div',{
  baseStyle: {
    position: 'absolute',
    w: '100%',
    bottom: 4,
    left: 0
  }
});
