import React from 'react';
import { faBed as faBedDuo } from '@fortawesome/pro-duotone-svg-icons/faBed';
import { faShower as faShowerDuo } from '@fortawesome/pro-duotone-svg-icons/faShower';
import { faUsers as faUsersDuo } from '@fortawesome/pro-duotone-svg-icons/faUsers';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/messages';
import LycanFormattedMessage from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';
import { faPaw } from '@fortawesome/pro-regular-svg-icons/faPaw';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faHouse } from '@fortawesome/pro-regular-svg-icons/faHouse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Tooltip, Text, Box } from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';
import TouchTooltip from '@rentivo/gatsby-core/src/components/ui/TouchTooltip';


const listingMetaStyleProps = {
  as: 'span',
  fontWeight: 'medium',
  mr: 4,
  color: 'primary.500',
  css: {
    '> *:last-item': { marginRight: 0 }
  }
};

const listingMetaItemStyleProps = {
  as: 'span',
  fontWeight: 'medium'
};

const PropertyListingMetaComponent = ({
  bathrooms,
  bedrooms,
  sleeps,
  maxOccupancy,
  overflow,
  showDetail,
  showLabels,
  maxPets,
  type,
  isPetFriendly,
  className,
  styleProps = {}
}) => {

  const realSleeps = (maxOccupancy && maxOccupancy > 0) ? maxOccupancy : sleeps;

  const sleepsLabel = <Text {...listingMetaItemStyleProps}>{showLabels ? <><AwesomeIcon mr={1} icon={faUsersDuo}/> <FormattedMessage {...messages.propertyListingGuestsCountLabel} values={{count: realSleeps}}/></> : <>{realSleeps} <AwesomeIcon mr={1} icon={faUsersDuo}/></>}</Text>;
  const bedroomsLabel = <Text {...listingMetaItemStyleProps}>{showLabels ? <><AwesomeIcon mr={1} icon={faBedDuo}/> <FormattedMessage {...messages.propertyListingBedroomsCountLabel} values={{count: bedrooms}}/></> : <>{bedrooms} <AwesomeIcon mr={1} icon={faBedDuo}/></>}</Text>;
  const bathroomsLabel = <Text {...listingMetaItemStyleProps}>{showLabels ? <><AwesomeIcon mr={1} icon={faShowerDuo}/> <FormattedMessage {...messages.propertyListingBathroomsCountLabel} values={{count: bathrooms}}/></> : <>{bathrooms} <AwesomeIcon mr={1} icon={faShowerDuo}/></>}</Text>;
  const typeLabel = <Text {...listingMetaItemStyleProps}>{showLabels ? <><AwesomeIcon mr={1} icon={faHouse}/> <LycanFormattedMessage message={type}/></> : <AwesomeIcon icon={faHouse}/>}</Text>;
  const maxPetsLabel = <Text {...listingMetaItemStyleProps}>{showLabels ? <><AwesomeIcon mr={1} icon={faPaw}/> <FormattedMessage {...messages.propertyListingPetsCountLabel} values={{count: maxPets}}/></> : <>{maxPets} <AwesomeIcon mr={1} icon={faPaw}/></>}</Text>;
  const petFriendlyLabel = <Text {...listingMetaItemStyleProps}>{showLabels ? <><AwesomeIcon mr={1} icon={faPaw}/> <FormattedMessage {...messages.propertyListingPetsLabel}/></> : <AwesomeIcon mr={1} icon={faPaw}/>}</Text>;

  return (
    <Flex
      wrap="wrap"
      className={className}
      {...styleProps}
    >
      {(type && showDetail) && (
        <Text {...listingMetaStyleProps}>
          {!showLabels ? (
            <TouchTooltip hasArrow maxW={200} label={<LycanFormattedMessage message={type}/>}>{typeLabel}</TouchTooltip>
          ) : typeLabel}
        </Text>
      )}

      {(realSleeps !== undefined && realSleeps > 0) && (
        <Text {...listingMetaStyleProps}>
          {(overflow > 0) ? (
            <TouchTooltip hasArrow maxW={200} label={<Box><FormattedMessage {...messages.propertyListingGuestsCountLabel} values={{count: sleeps}}/>{overflow > 0 && (<FormattedMessage {...messages.propertyListingAdditionalGuestsCountLabel} values={{count: overflow}}/>)}</Box>}><Box>{sleepsLabel} <AwesomeIcon mr={1} icon={faInfoCircle}/></Box></TouchTooltip>
          ) : sleepsLabel}
        </Text>
      )}

      {(bedrooms !== undefined && bedrooms > 0) && (
        <Text {...listingMetaStyleProps}>
          {!showLabels ? (
            <TouchTooltip hasArrow maxW={200} label={<FormattedMessage {...messages.propertyListingBedroomsCountLabel} values={{count: bedrooms}}/>}>{bedroomsLabel}</TouchTooltip>
          ) : bedroomsLabel}
        </Text>
      )}

      {(bathrooms !== undefined && bathrooms > 0) && (
        <Text {...listingMetaStyleProps}>
          {!showLabels ? (
            <TouchTooltip hasArrow maxW={200} label={<FormattedMessage {...messages.propertyListingBathroomsCountLabel} values={{count: bathrooms}}/>}>{bathroomsLabel}</TouchTooltip>
          ) : bathroomsLabel}
        </Text>
      )}

      {((maxPets && maxPets > 0) || isPetFriendly) && (
        <Text {...listingMetaStyleProps}>
          {(maxPets && maxPets > 0) ? (
            <>
              {!showLabels ? (
                <TouchTooltip hasArrow maxW={200} label={<FormattedMessage {...messages.propertyListingPetsCountLabel} values={{count: maxPets}}/>}>{maxPetsLabel}</TouchTooltip>
              ) : maxPetsLabel}
            </>
          ) : (
            <>
              {!showLabels ? (
                <TouchTooltip hasArrow maxW={200} label={<FormattedMessage {...messages.propertyListingPetsLabel}/>}>{petFriendlyLabel}</TouchTooltip>
              ) : petFriendlyLabel}
            </>
          )}
        </Text>
      )}
    </Flex>
  );
};

export default PropertyListingMetaComponent;
