import React from 'react';
import {faMapPin} from '@fortawesome/pro-regular-svg-icons/faMapPin';
import {Text} from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';

const PropertyAddressComponent = ({fullAddress, city, stateProvince, countryISO2, className, styleProps, showFullAddress}) => {
    if (!city && !countryISO2) return null;

    return (
        <Text as="div" className={className} {...styleProps}>
            <AwesomeIcon mr={2} icon={faMapPin}/>
            {showFullAddress && (
                <span>
                    {fullAddress.addressLine1 && <span>{fullAddress.addressLine1}, </span>}
                    {fullAddress.addressLine2 && <span>{fullAddress.addressLine2}, </span>}
                </span>
            )}
            <span>{city}, </span>
            {stateProvince && <span>{stateProvince}, </span>}
            <span>{countryISO2}</span>
            {showFullAddress && <span>, {fullAddress.zipPostCode}</span>}
        </Text>
    );
};

export default PropertyAddressComponent;
