import React from 'react';

export const preloadImages = function (srcs, callback = null) {
  let img;
  let remaining = srcs.length;
  for (let i = 0; i < srcs.length; i++) {
    if (srcs[i] === undefined) {
      continue;
    }

    img = new Image;
    img.onload = function () {
      --remaining;
      if (remaining <= 0) {
        if(callback) callback();
      }
    };
    img.src = srcs[i];
  }
};
