import React from 'react';
import ConditionalWrapper from '@rentivo/gatsby-core/src/components/generic/ConditionalWrapper';
import Button from '@rentivo/gatsby-core/src/components/ui/ChakraButton';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyTagsShort/messages';
import { useIntl } from 'react-intl';
import LycanFormattedMessage from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';
import {
  Tooltip,
  Text
} from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import TouchTooltip from '@rentivo/gatsby-core/src/components/ui/TouchTooltip';

const PropertyTagsShortComponent = ({className, tags, tabIndex, style, ...rest}) => {
  const { formatMessage } = useIntl();
  if(!tags || !tags.length) return null;

  const firstTagName = <LycanFormattedMessage message={tags[0].value}/>;

  return (
    <div className={className} style={style}>
      <ConditionalWrapper condition={tags.length > 1} wrapper={children => (
        <TouchTooltip
          hasArrow
          label={
            <>
              {tags.map(tag => (
                <p><AwesomeIcon size="sm" icon={faThumbsUp} mr={1}/> <LycanFormattedMessage message={tag.value}/></p>
              ))}
            </>
          }
        >
          {children}
        </TouchTooltip>
      )}>
        <Button
          type="white"
          tabIndex={tabIndex}
          {...rest}
        >
          <Text isTruncated mb={0}><AwesomeIcon size="sm" position="relative" icon={faThumbsUp} mr={1}/> {tags.length <= 1 ? firstTagName : formatMessage(messages.popoverButtonLabel, {firstTag: firstTagName, tagsCount: tags.length - 1})}</Text>
        </Button>
      </ConditionalWrapper>
    </div>
  );
};

export default PropertyTagsShortComponent;
