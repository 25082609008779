import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyTagsShort from '@rentivo/gatsby-core/src/components/property/PropertyTagsShort/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyTags,
} from '@rentivo/gatsby-core/src/selectors/property';
import { connect } from 'react-redux';
import {
  selectPropertyTagsAcceptsConfig,
  selectPropertyTagsShowMaxTagsConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';

const defaultProps = {
  className: '',
  tabIndex: 0
};

const propertySelector = createSelector(
  selectPropertyTags,
  (tags) => {
    return {
      tags
    };
  }
);

const mapStateToProps = (state, { accepts, tags, showMaxTags }) => createSelector(
  selectPropertyTagsShowMaxTagsConfig,
  selectPropertyTagsAcceptsConfig,
  (showMaxTagsConfig, acceptsConfig) => {
    showMaxTags = (showMaxTags !== undefined) ? showMaxTags : showMaxTagsConfig;
    accepts = (accepts && accepts.length) ? accepts : acceptsConfig;
    const filteredTags = (accepts && accepts.length) ? tags.filter(t => accepts.includes(t.tag)) : tags.filter(t => t.publicWebsiteText && this.publicWebsiteText !== "");
    return {
      tags: filteredTags
    }
  }
);

const enhance = compose(
  withProperty(propertySelector),
  connect(mapStateToProps)
);

PropertyTagsShort.defaultProps = defaultProps;

export default enhance(PropertyTagsShort);
