import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '@rentivo/gatsby-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { preloadImages } from '@rentivo/gatsby-core/src/utils/images';
import {
  StyledBlockImageGallery,
  StyledBlockImageGalleryAction,
  StyledBlockImageGalleryContainer,
  StyledBlockImageGalleryStatic
} from '@rentivo/gatsby-core/src/components/property/BlockImageGallery/styles';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';
import Container from '@rentivo/gatsby-core/src/components/ui/Container';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/BlockImageGallery/messages';
import BackButton from '@rentivo/gatsby-core/src/components/property/BackButton';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { chakra, useDisclosure, Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  ModalCloseButton } from '@chakra-ui/react';
import ReactPlayer from 'react-player';

const BlockImageGalleryComponent = ({staticPhotos, galleryPhotos, video, headline, className, isComputer, styleProps}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { isOpen: isVideoOpen, onOpen: onVideoOpen, onClose: onVideoClose } = useDisclosure()
  const [hasPreloaded, setHasPreloaded] = useState(false);

  const toggleOpen = (selectedIndex) => {
    setIsOpen(!isOpen);
    setSelectedIndex(selectedIndex);
  };

  const handleMouseOverPreload = () => {
    if(!hasPreloaded) {
      preloadImages(galleryPhotos.map(p => p.source));
      setHasPreloaded(true);
    }
  };

  return (
    <chakra.div className={className} position="relative" {...styleProps}>
      <StyledBlockImageGalleryContainer onMouseOver={handleMouseOverPreload}>
        {staticPhotos.map((image, j) => (
          <>
          {(j === 1 && video) ? (
            <StyledBlockImageGalleryStatic
              className={classNames(`ig-${j + 1}`, { three: staticPhotos.length < 5})}
              key={image.source}
            >
              <Loader bg={true}/>
              <img alt={image.caption ? image.caption : `${headline} Photo ${j + 1}`} src={image.source} data-seqid={j}/>
              <chakra.div position="absolute" inset="0px" zIndex={300}>
                <chakra.div
                  position="absolute"
                  minWidth="125%"
                  minHeight="125%"
                  paddingBottom="56.25%"
                  zIndex={1}
                  width="auto"
                  height="auto"
                  top="51%"
                  left="51%"
                  objectFit="cover"
                  transform="translate(-51%, -51%)"
                  bgColor="gray.100"
                  onClick={onVideoOpen}
                  _after={{
                    content: '""',
                    position: 'absolute',
                    inset: '0px',
                    zIndex: 999
                  }}
                  css={{
                    '.react-player': {
                      'position': 'absolute',
                      'inset': '0px',
                      'zIndex': 200
                    }
                  }}
                >
                  <ReactPlayer 
                    url={video} 
                    width='100%' 
                    height='100%' 
                    className='react-player'
                    loop={true}
                    playing={!isVideoOpen}
                    light={false}
                    playsinline={true}
                    volume={null}
                    muted={true}
                    controls={false}
                    config={{
                      file: {
                        attributes: {
                          muted: true,
                          controls: false,
                          autoPlay: true,
                          loop: true,
                          playsinline: true
                        }
                      },
                      vimeo: {
                        playerOptions: {
                          autoPause: false,
                          autoPlay: true,
                          background: true,
                          controls: false,
                          loop: true,
                          muted: true
                        }
                      },
                      youtube: {
                        playerVars: { showinfo: 1, playsinline: 1 },
                        embedOptions: { autoPlay: 1, controls: 0, loop: 1 }
                      }
                    }}
                  />
                </chakra.div>
              </chakra.div>
            </StyledBlockImageGalleryStatic>
          ): (
            <StyledBlockImageGalleryStatic
              className={classNames(`ig-${j + 1}`, { three: staticPhotos.length < 5})}
              key={image.source}
              onClick={() => toggleOpen(j)}
            >
              <Loader bg={true}/>
              <img alt={image.caption ? image.caption : `${headline} Photo ${j + 1}`} src={image.source} data-seqid={j}/>
            </StyledBlockImageGalleryStatic>
          )}
          </>

        ))}
      </StyledBlockImageGalleryContainer>

      {galleryPhotos.length && (
        <StyledBlockImageGalleryAction>
          <Container>
            <BackButton type="white" mr={4} mb={1} fontSize={{base: 'sm', md: 'md'}} p={{base: 3, md: 5}} h={{base: '32px', md: '40px'}}/>
            <Button mb={1} fontSize={{base: 'sm', md: 'md'}} p={{base: 3, md: 5}} h={{base: '32px', md: '40px'}} leftIcon={<FontAwesomeIcon icon={faImages}/>} htmlType="button" type="white" onMouseOver={handleMouseOverPreload} onClick={() => toggleOpen(0)}><FormattedMessage {...messages.viewGalleryButton} values={{photos: galleryPhotos.length}}/></Button>
          </Container>
        </StyledBlockImageGalleryAction>
      )}

      <ModalGateway>
        {isOpen ? (
          <Modal onClose={toggleOpen}>
            <Carousel
              currentIndex={selectedIndex}
              views={galleryPhotos}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      {(video) && (
        <ChakraModal isOpen={isVideoOpen} onClose={onVideoClose} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton position="fixed" top="12px" color="white" right="15px" />
            <ModalBody p={0}>
              <Box position="relative" pt="56.25%" css={{
                    '.react-player': {
                      'position': 'absolute',
                      'inset': '0px',
                      'zIndex': 200
                    }
                  }}>
                <ReactPlayer 
                  url={video} 
                  width='100%' 
                  height='100%' 
                  className='react-player'
                  controls={true}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </ChakraModal>
      )}
    </chakra.div>
  );
};

export default BlockImageGalleryComponent;
