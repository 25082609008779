import React from 'react'
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import ProductAccommodation from '@rentivo/gatsby-core/src/components/schema/ProductAccommodation/component';
import {
  selectRoutePathsConfig,
  selectSiteStructuredDataBrandConfig,
  selectSiteStructuredDataOrganizationConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { selectDefaultLang, selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import {
  makeSelectPropertyHeadline, selectPropertyAddressCity, selectPropertyAddressCountryCode, selectPropertyAddressState,
  selectPropertyAverageRatingFromReviews,
  selectPropertyFeatures,
  selectPropertyIsPetFriendlyFromFeatures,
  selectPropertyListingBathrooms,
  selectPropertyListingBedrooms,
  selectPropertyLocationLat, selectPropertyLocationLng,
  selectPropertyMedia,
  selectPropertyName,
  selectPropertyPhotoFeaturedImageFromMedia,
  selectPropertyPricingVisualCurrency,
  selectPropertyPricingVisualNightlyLow,
  selectPropertyReviews,
  selectPropertySlug,
  selectPropertyTextByTypeByLocaleFromTexts,
  selectPropertyTexts
} from '@rentivo/gatsby-core/src/selectors/property';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import { PRIMARY_DESCRIPTION } from '@rentivo/gatsby-core/src/constants/lycanConstants';
import { getClosestContentObjByLocale, getFriendlyFeatureName } from '@rentivo/gatsby-core/src/utils/getters';
import { selectPropertyPath } from '@rentivo/gatsby-core/src/selectors/paths';
import { generateLocaleLink } from '@rentivo/gatsby-core/src/components/navigation/LocaleLink/utils';
import { stripHtmlRegex } from '@rentivo/gatsby-core/src/utils/strings';

const propertySelector = (state, { lang, brand, defaultLang, routePaths }) => createSelector(
  selectPropertyName,
  selectPropertySlug,
  makeSelectPropertyHeadline,
  selectPropertyTexts,
  selectPropertyFeatures,
  selectPropertyMedia,
  selectPropertyPricingVisualNightlyLow,
  selectPropertyPricingVisualCurrency,
  selectPropertyReviews,
  selectPropertyListingBedrooms,
  selectPropertyListingBathrooms,
  selectPropertyLocationLat,
  selectPropertyLocationLng,
  selectPropertyAddressCountryCode,
  selectPropertyAddressState,
  selectPropertyAddressCity,
  (name, slug, headline, texts, features, media, price, currency, reviews, bedrooms, bathrooms, latitude, longitude, addressCountry, addressRegion, addressLocality) => {
    const defaultAverageRating = 4.5;
    const description = stripHtmlRegex(selectPropertyTextByTypeByLocaleFromTexts(texts, PRIMARY_DESCRIPTION, lang));
    const amenityFeature = features.map(f => ({'@type': 'LocationFeatureSpecification', 'name': getFriendlyFeatureName(f.type), 'value': true}));
    const image = selectPropertyPhotoFeaturedImageFromMedia(media);
    const petsAllowed = selectPropertyIsPetFriendlyFromFeatures(features);
    const propertyUrl = selectPropertyPath(slug, routePaths);
    const localisedPropertyUrl = generateLocaleLink(lang, defaultLang, propertyUrl);
    const averageRating = parseFloat(selectPropertyAverageRatingFromReviews(reviews, 5));
    const offers = [{
      '@type': 'Offer',
      price: price,
      priceCurrency: currency,
      priceValidUntil: dayjs().add(1, 'month').toISOString(),
      url: localisedPropertyUrl,
      availability: 'LimitedAvailability'
    }];
    const bestReviews = reviews.filter(review => review.rating && review.rating.score && review.rating.score >= 4).sort((a, b) => b.rating.score - a.rating.score);
    const bestReview = (bestReviews.length) ? bestReviews[0] : null;
    const localisedBestReview = (bestReview && bestReview.review) ? getClosestContentObjByLocale(bestReview.review, lang) : null;

    return {
      accommodation: {
        name,
        image: (image && image.uri) ? image.uri : undefined,
        amenityFeature,
        numberOfBedrooms: bedrooms,
        numberOfBathroomsTotal: bathrooms,
        petsAllowed,
        description,
        hasMap: localisedPropertyUrl,
        latitude,
        longitude,
        address: (addressCountry || addressLocality || addressRegion) ? {
          "@type": "PostalAddress",
          addressCountry: (addressCountry) ? addressCountry : undefined,
          addressRegion: (addressRegion) ? addressRegion : undefined,
          addressLocality: (addressLocality) ? addressLocality : undefined,
        } : undefined
      },
      product: {
        name,
        description,
        image: (image && image.uri) ? image.uri : undefined,
        brand,
        sku: slug,
        mpn: slug,
        offers,
        url: localisedPropertyUrl,
        aggregateRating: (reviews.length) ? {
          ratingCount: reviews.length,
          reviewCount: reviews.length,
          ratingValue: averageRating,
          bestRating: 5
        } : {
          ratingCount: 1,
          reviewCount: 1,
          ratingValue: defaultAverageRating,
          bestRating: 5
        },
        review: (bestReview) ? {
          '@type': 'Review',
          name: (localisedBestReview.title) ? localisedBestReview.title : undefined,
          reviewBody: (localisedBestReview.content) ? localisedBestReview.content : undefined,
          reviewRating: {
            "@type": "Rating",
            bestRating: bestReview.rating.max,
            ratingValue: bestReview.rating.score,
          },
          author: (bestReview.reviewerName) ? {
            '@type': 'Person',
            name: bestReview.reviewerName
          } : undefined
        } : {
          '@type': 'Review',
          reviewBody: description,
          reviewRating: {
            "@type": "Rating",
            bestRating: 5,
            ratingValue: defaultAverageRating,
          },
          author: {
            '@type': 'Organization',
            name: (brand.name) ? brand.name : undefined
          }
        }
      }
    }
  }
)(state);

const mapStateToProps = createSelector(
  selectLang,
  selectDefaultLang,
  selectRoutePathsConfig,
  selectSiteStructuredDataOrganizationConfig,
  selectSiteStructuredDataBrandConfig,
  (lang, defaultLang, routePaths, organization, brand) => {

    const brandOrOrganization = (brand) ? brand : organization;
    const brandType = (brand) ? 'Brand' : 'Organization';
    brand = (brandOrOrganization) ? {
      '@type': brandType,
      ...brandOrOrganization
    } : null;

    return {
      lang,
      defaultLang,
      routePaths,
      brand
    }
  }
);

const enhance = compose(
  connect(mapStateToProps),
  withProperty(propertySelector)
);

ProductAccommodation.defaultProps = {
  type: ['Product', 'Accommodation']
};

export default enhance(ProductAccommodation);
