import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyPolicies from '@rentivo/gatsby-core/src/components/property/PropertyPolicies/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyLocale, selectPropertyPolicies, selectPropertyPoliciesByTypes,
} from '@rentivo/gatsby-core/src/selectors/property';
import { BOOKING_TERMS } from '@rentivo/gatsby-core/src/constants/lycanConstants';

const defaultProps = {
  showTitle: true,
  title: null,
  lines: 5,
  showPolicies: [BOOKING_TERMS],
  wrapper: ({children}) => children
};

const propertySelector = (state, { showPolicies }) => createSelector(
  selectPropertyPolicies,
  selectPropertyLocale,
  (allPolicies, locale) => {
    showPolicies = (showPolicies) ? showPolicies : defaultProps.showPolicies;
    return {
      policies: selectPropertyPoliciesByTypes(allPolicies, locale, showPolicies)
    };
  }
)(state);

const enhance = compose(
  withProperty(propertySelector)
);

PropertyPolicies.defaultProps = defaultProps;

export default enhance(PropertyPolicies);
