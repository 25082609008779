import React from 'react';
import {
  StyledPropertyDescription
} from '@rentivo/gatsby-core/src/components/property/PropertyDescription/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyDescription/messages';
import TruncateText from '@rentivo/gatsby-core/src/components/ui/TruncateText';
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle';

const PropertyDescriptionComponent = ({wrapper: Wrapper, description, showTitle, title, lines, className}) => {
  if(!description) return null;

  return (
    <Wrapper>
      <StyledPropertyDescription className={className}>
        {showTitle && <PropertyComponentTitle>{title ? title : <FormattedMessage {...messages.title}/>}</PropertyComponentTitle>}
        <TruncateText
          lines={lines}
          text={description}
        />
      </StyledPropertyDescription>
    </Wrapper>
  );
};

export default PropertyDescriptionComponent;
