import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyHead from '@rentivo/gatsby-core/src/components/property/PropertyHead/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyTags,
  makeSelectPropertyHeadline,
  selectPropertySlug,
  makeSelectPropertyLocation,
  selectPropertyAddressCity,
  selectPropertyAddressCountryCode,
  selectPropertyAddressState,
  selectPropertyListingBathrooms,
  selectPropertyListingBedrooms,
  selectPropertyListingMaxOccupancy, selectPropertyListingMaxPets,
  selectPropertyListingSleeps, selectPropertyListingType, selectPropertyMedia,
  selectPropertyName, selectPropertyPhotoFeaturedImageFromMedia
} from '@rentivo/gatsby-core/src/selectors/property';
import {
  selectPropertyHeadlineShowSubtitleConfig,
  selectSiteContentVariablesConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { connect } from 'react-redux';

const propertySelector = (state) => createSelector(
  selectPropertyTags,
  selectPropertyName,
  selectPropertyMedia,
  selectPropertyAddressCity,
  selectPropertyAddressState,
  selectPropertyAddressCountryCode,
  makeSelectPropertyLocation,
  makeSelectPropertyHeadline,
  selectPropertySlug,
  selectPropertyListingBathrooms,
  selectPropertyListingBedrooms,
  selectPropertyListingSleeps,
  selectPropertyListingMaxOccupancy,
  selectPropertyListingMaxPets,
  selectPropertyListingType,
  (tags, name, media, city, state, countryCode, location, headline, propertySlug, bathrooms, bedrooms, sleeps, maxOccupancy, maxPets, type) => ({
    headline,
    tags,
    propertySlug,
    image: selectPropertyPhotoFeaturedImageFromMedia(media),
    extraVars: {
      name,
      city,
      state,
      countryCode,
      headline,
      location,
      bathrooms,
      bedrooms,
      sleeps,
      maxOccupancy,
      maxPets,
      type
    }
  })
)(state);

const mapStateToProps  = (state, { tags = [] }) => createSelector(
  selectSiteContentVariablesConfig,
  selectPropertyHeadlineShowSubtitleConfig,
  (siteVariables, showSubtitle) => {
    
    const [noIndex] = tags.filter(t => t.tag === 'website' && t.value === 'noindex');
    
    return {
      siteVariables,
      showSubtitle,
      hasNoIndex: (noIndex !== undefined)
    }
  }
);

const enhance = compose(
  withProperty(propertySelector),
  connect(mapStateToProps),
);


PropertyHead.defaultProps = {
  className: ''
};

export default enhance(PropertyHead);
