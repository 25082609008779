import React, {forwardRef} from 'react';
import Footer from '@rentivo/gatsby-theme-barcelona/src/components/navigation/Footer';
import ProductAccommodation from '@rentivo/gatsby-core/src/components/schema/ProductAccommodation';
import HeaderNavbar from '@rentivo/gatsby-core/src/components/ui/HeaderNavbar';
import TopBar from '@rentivo/gatsby-core/src/components/ui/TopBar';
import {Box} from '@chakra-ui/react';
import UpdateBar from '@rentivo/gatsby-core/src/components/ui/UpdateBar';

const PropertyLayoutComponent = forwardRef(({children, footer, updateBar, topBar, menuItems, publicNav, overrideLayout}, ref) => {
    let nav = <div>
        <ProductAccommodation/>
        {(topBar && topBar.length > 0) && (
            <TopBar blocks={topBar}/>
        )}
        {(updateBar && updateBar.length > 0) && (
            <UpdateBar blocks={updateBar}/>
        )}
        <HeaderNavbar
            overrideLayout={overrideLayout}
            publicNav={publicNav}
            {...menuItems}
        />
    </div>

    return (
        <Box bg={{base: 'white', lg: 'gray.100'}} id="property-layout" className="layout">
            {nav}

            <Box mb={6} ref={ref} className="layout-content">
                {children}
            </Box>
            {footer && <Footer blocks={footer}/>}
        </Box>
    );
});

export default PropertyLayoutComponent;
