import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyRooms from '@rentivo/gatsby-core/src/components/property/PropertyRooms/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyListingBathrooms,
  selectPropertyListingBedrooms, selectPropertyLocationLng,
  selectPropertyRooms,
  selectPropertyRoomsByTypeFromRooms,
} from '@rentivo/gatsby-core/src/selectors/property';
import { BATHROOM, BEDROOM } from '@rentivo/gatsby-core/src/constants/lycanConstants';
import { FormattedMessage } from 'react-intl';
import propertyMessages from '@rentivo/gatsby-core/src/components/property/messages';

const defaultProps = {
  type: BEDROOM,
  showTitle: true,
  showTotal: true,
  title: null,
  wrapper: ({children}) => children
};

const propertySelector = (state, { type }) => createSelector(
  selectPropertyRooms,
  selectPropertyListingBedrooms,
  selectPropertyListingBathrooms,
  (rooms, bedrooms, bathrooms) => {
    return {
      rooms: selectPropertyRoomsByTypeFromRooms(rooms, type),
      totalMessage: (type === BATHROOM) ? <FormattedMessage {...propertyMessages.propertyListingBathroomsCountLabel} values={{count: bathrooms}}/> : <FormattedMessage {...propertyMessages.propertyListingBedroomsCountLabel} values={{count: bedrooms}}/>
    };
  }
)(state);

const enhance = compose(
  withProperty(propertySelector)
);

PropertyRooms.defaultProps = defaultProps;

export default enhance(PropertyRooms);
