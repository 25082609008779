import React from 'react';
import JsonLd from '@rentivo/gatsby-core/src/components/schema/JsonLd';

const ProductAccommodationComponent = ({product, accommodation}) => {
  if(!product && !accommodation) return null;
  if(product.offers && product.offers[0] && !product.offers[0].price) return null; // Don't show without a price.
  if(product.offers && product.offers[0] && !parseFloat(product.offers[0].price) > 0) return null; // Don't show without a price.
  return (
    <>
      {product && accommodation && <JsonLd data={[product, accommodation]} type={[`Product`, `Accommodation`]}/>}
    </>
  );
};

export default ProductAccommodationComponent;
