import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import BackButton from '@rentivo/gatsby-core/src/components/property/BackButton/component';
import { connect } from 'react-redux';
import { selectSearchResultsOpenInNewTabConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';


const mapStateToProps = createSelector(
  selectSearchResultsOpenInNewTabConfig,
  (openInNewTab) => ({
      openInNewTab
    })
);

const enhance = compose(
  connect(mapStateToProps),
);

BackButton.defaultProps = {
  text: null,
  useButton: true
};

export default enhance(BackButton);
