import { defineMessages } from 'react-intl';

export const scope = 'components.property';

export default defineMessages({
  propertyListingStudioLabel: {
    id: `${scope}.property.listing.studio.label`,
    defaultMessage: 'Studio',
  },
  propertyListingBedroomsCountLabel: {
    id: `${scope}.property.listing.bedrooms.count.label`,
    defaultMessage: '{count, plural, =0 {Studio} one {1 bedroom} other {{count} bedrooms}}',
  },
  propertyListingBedroomsLabel: {
    id: `${scope}.property.listing.bedrooms.label`,
    defaultMessage: '{count, plural, one {Bedroom} other {Bedrooms}}',
  },
  propertyListingGuestsCountLabel: {
    id: `${scope}.property.listing.guests.count.label`,
    defaultMessage: '{count, plural, =0 {0 guests} one {1 guest} other {{count} guests}}',
  },
  propertyListingAdditionalGuestsCountLabel: {
    id: `${scope}.property.listing.additional.guests.count.label`,
    defaultMessage: ' + {count, plural, =0 {0 additional guests} one {1 additional guest} other {{count} additional guests}} (fees may apply)',
  },
  propertyListingGuestsLabel: {
    id: `${scope}.property.listing.guests.label`,
    defaultMessage: '{count, plural, one {Guest} other {Guests}}',
  },
  propertyListingBathroomsCountLabel: {
    id: `${scope}.property.listing.bathrooms.count.label`,
    defaultMessage: '{count, plural, =0 {0 bathrooms} one {1 bathroom} other {{count} bathrooms}}',
  },
  propertyListingBathroomsLabel: {
    id: `${scope}.property.listing.bathrooms.label`,
    defaultMessage: '{count, plural, one {Bathroom} other {Bathrooms}}',
  },
  propertyListingPetsCountLabel: {
    id: `${scope}.property.listing.pets.count.label`,
    defaultMessage: '{count, plural, =0 {No pets} one {1 pet} other {{count} pets}}',
  },
  propertyListingPetsLabel: {
    id: `${scope}.property.listing.pets.label`,
    defaultMessage: 'Pet Friendly',
  }
});
