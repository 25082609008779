import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import BlockImageGallery from '@rentivo/gatsby-core/src/components/property/BlockImageGallery/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  makeSelectPropertyHeadline,
  selectPropertyMedia,
  selectPropertyMediaByCategoryFromMedia
} from '@rentivo/gatsby-core/src/selectors/property';
import {
  selectStaticPhotos,
  selectGalleryPhotos
} from '@rentivo/gatsby-core/src/components/property/BlockImageGallery/selectors';
import { makeSelectGetCdnImageUrl } from '@rentivo/gatsby-core/src/components/ui/CdnImage/selectors';
import { connect } from 'react-redux';
import { withResponsive } from '@rentivo/gatsby-core/src/context/ResponsiveContext';
import { selectDefaultLang, selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import {VIDEO} from "@rentivo/gatsby-core/src/constants/lycanConstants";

const mapStateToProps = createSelector(
  makeSelectGetCdnImageUrl(),
  selectLang,
  selectDefaultLang,
  (getCdnImageUrl, lang, defaultLang) => ({
    getCdnImageUrl,
    lang,
    defaultLang
  })
);

const propertySelector = (state, { getCdnImageUrl, lang, defaultLang }) => createSelector(
  selectPropertyMedia,
  makeSelectPropertyHeadline,
  (media, headline) => {

    const videos = selectPropertyMediaByCategoryFromMedia(media, [VIDEO]);
    const [featuredVideo] = videos.filter(v => v.isFeatured);

    let video = (featuredVideo) ? featuredVideo : (videos && videos.length) ? videos[0] : null;
    //video = null;
    return {
      staticPhotos: selectStaticPhotos(media, 0, 5, getCdnImageUrl, headline, lang, defaultLang),
      galleryPhotos: selectGalleryPhotos(media, getCdnImageUrl, headline, lang, defaultLang),
      headline,
      video: (video) ? video.uri : null
    }
  }
)(state);

const enhance = compose(
  connect(mapStateToProps),
  withResponsive,
  withProperty(propertySelector)
);

BlockImageGallery.defaultProps = {
  className: '',
  styleProps: {}
};

export default enhance(BlockImageGallery);
