import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyDescription from '@rentivo/gatsby-core/src/components/property/PropertyDescription/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyLocale,
  selectPropertyTextByTypeByLocaleFromTexts,
  selectPropertyTexts
} from '@rentivo/gatsby-core/src/selectors/property';
import { PRIMARY_DESCRIPTION } from '@rentivo/gatsby-core/src/constants/lycanConstants';
import { selectLang, selectLocale } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { connect } from 'react-redux';

const defaultProps = {
  type: PRIMARY_DESCRIPTION,
  showTitle: true,
  title: null,
  lines: 5,
  wrapper: ({children}) => children
};

const mapStateToProps = createSelector(
  selectLang,
  (lang) => ({lang})
);

const propertySelector = (state, { type, lang }) => createSelector(
  selectPropertyTexts,
  (texts) => {
    type = (type) ? type : defaultProps.type;
    return {
      description: selectPropertyTextByTypeByLocaleFromTexts(texts, type, lang)
    };
  }
)(state);

const enhance = compose(
  connect(mapStateToProps),
  withProperty(propertySelector)
);

PropertyDescription.defaultProps = defaultProps;

export default enhance(PropertyDescription);
