import React from 'react'
import { useIntl } from 'react-intl'
import { messagesToFormattedObject } from '@rentivo/gatsby-core/src/utils/messages'
import messages, {
  messagesObject,
} from '@rentivo/gatsby-core/src/components/property/PropertyHead/messages'
import { Helmet } from 'react-helmet'
import { window } from 'browser-monads'
import { getMessageString } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/utils'
import OpenGraph from '@rentivo/gatsby-core/src/components/schema/OpenGraph'

const PropertyHeadComponent = ({
  propertySlug,
  hasNoIndex,
  extraVars,
  image,
  siteVariables,
}) => {
  const { formatMessage } = useIntl()
  extraVars.type = getMessageString(extraVars.type, formatMessage)
  const variables = { ...siteVariables, ...extraVars }
  const content = messagesToFormattedObject(
    formatMessage,
    messages,
    messagesObject,
    variables
  )

  const pageTitle = content.pageTitle
  const pageSubtitle = content.pageSubtitle
  const seoTitle = content.seoTitle
  const seoDesc = content.seoDesc

  const urlPieces = [
    window.location.protocol,
    '//',
    window.location.host,
    window.location.pathname,
  ]
  const url = urlPieces.join('')

  return (
    <>
      <Helmet>
        {seoTitle && <title>{seoTitle}</title>}
        {seoDesc && <meta name="description" content={seoDesc} />}
        {hasNoIndex && <meta name="robots" content="noindex" />}
        {window.location && <link rel="canonical" href={url} />}
        <body
          className={`page-property ${
            propertySlug ? `page-property-${propertySlug}` : ``
          }`}
        />
      </Helmet>

      <OpenGraph
        title={pageTitle ? pageTitle : seoTitle}
        description={pageSubtitle ? pageSubtitle : seoDesc}
        imageSrc={image}
      />
    </>
  )
}

export default PropertyHeadComponent
