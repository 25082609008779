import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyFeatures from '@rentivo/gatsby-core/src/components/property/PropertyFeatures/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyArrivalCheckInEndTime, selectPropertyArrivalCheckInStartTime, selectPropertyDepartureCheckOutTime,
  selectPropertyFeatures,
  selectPropertyFeatureSetsByLocaleFromFeatures,
  selectPropertyLocale
} from '@rentivo/gatsby-core/src/selectors/property';
import { PRIMARY_DESCRIPTION } from '@rentivo/gatsby-core/src/constants/lycanConstants';

const defaultProps = {
  type: PRIMARY_DESCRIPTION,
  showTitle: true,
  title: null,
  lines: 5,
  wrapper: ({children}) => children
};

const propertySelector = createSelector(
  selectPropertyFeatures,
  selectPropertyLocale,
  selectPropertyArrivalCheckInStartTime,
  selectPropertyArrivalCheckInEndTime,
  selectPropertyDepartureCheckOutTime,
  (features, locale, checkInStartTime, checkInEndTime, checkOutTime) => {
    return {
      features,
      locale,
      featureSets: selectPropertyFeatureSetsByLocaleFromFeatures(features, locale),
      checkInStartTime,
      checkInEndTime,
      checkOutTime
    };
  }
);

const enhance = compose(
  withProperty(propertySelector)
);

PropertyFeatures.defaultProps = defaultProps;

export default enhance(PropertyFeatures);
