import React from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyRates from '@rentivo/gatsby-core/src/components/property/PropertyRates/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyPricingDynamicPeriods
} from '@rentivo/gatsby-core/src/selectors/property';
import { FormattedDate, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import Price from '@rentivo/gatsby-core/src/components/generic/Price';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyRates/messages';
import {
  StyledPropertyRatesMinStayValue,
  StyledPropertyRatesPriceValue,
  StyledPropertyRatesSeasonDates,
  StyledPropertyRatesSeasonName,
  StyledPropertyRatesSeasonValue
} from '@rentivo/gatsby-core/src/components/property/PropertyRates/styles';

dayjs.extend(isSameOrAfter);

const defaultProps = {
  showTitle: true,
  title: null,
  wrapper: ({children}) => children
};

const propertySelector = (state, ownProps) => createSelector(
  selectPropertyPricingDynamicPeriods,
  (periods) => {

    // Filter rates before today

    periods = periods.filter(rate => {
      const conditions = get(rate, 'conditions', []).filter(c => c.startDate && c.endDate);
      const condition = (conditions.length) ? conditions[0] : false;
      if(condition) {
        return dayjs(condition.startDate, 'YYYY-MM-DD').isSameOrAfter(dayjs());
      } else {
        return false;
      }
    });

    const data = [];
    periods.forEach((rate, i) => {
      const description = get(rate, 'description', 'Season');
      const minimumNights = get(rate, 'minimumNights', 1);
      const currency = get(rate, 'currency', 'USD');
      const amount = get(rate, 'rate.amount', 0);
      const type = get(rate, 'rate.type', 'nightly');
      const period = (type === 'weekly') ? <FormattedMessage {...messages.weeklyLabel}/> : <FormattedMessage {...messages.nightlyLabel}/>;

      const conditions = get(rate, 'conditions', []).filter(c => c.startDate && c.endDate);
      const condition = (conditions.length) ? conditions[0] : false;

      if(condition) {

        const startDate = <FormattedDate value={dayjs(condition.startDate, 'YYYY-MM-DD').valueOf()} year="numeric" month="short" day="numeric"/>;
        const endDate = <FormattedDate value={dayjs(condition.endDate, 'YYYY-MM-DD').valueOf()} year="numeric" month="short" day="numeric"/>;

        data.push({
          key: i,
          season: (
            <StyledPropertyRatesSeasonValue>
              <StyledPropertyRatesSeasonName>{description}</StyledPropertyRatesSeasonName>
              <StyledPropertyRatesSeasonDates>{startDate} → {endDate}</StyledPropertyRatesSeasonDates>
            </StyledPropertyRatesSeasonValue>
          ),
          price: (
            <StyledPropertyRatesPriceValue>
              <Price
                price={amount}
                currency={currency}
                periodSuffix={period}
                defaultPrice={amount}
              />
            </StyledPropertyRatesPriceValue>
          ),
          minStay: (
            <StyledPropertyRatesMinStayValue>
              <FormattedMessage {...messages.minStayPeriod} values={{itemCount: minimumNights}}/>
            </StyledPropertyRatesMinStayValue>
          )
        });
      }
    });


    return {
      data
    };
  }
)(state);

const enhance = compose(
  withProperty(propertySelector)
);

PropertyRates.defaultProps = defaultProps;

export default enhance(PropertyRates);
