import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyHeader';

export const messagesObject = {
  pageTitle: {
    id: `${scope}.page.title`,
    defaultMessage: '{headline}'
  },
  pageSubtitle: {
    id: `${scope}.page.subtitle`,
    defaultMessage: '{headline}, {bedrooms, plural, =0 {} one {1 bedroom} other {{bedrooms} bedrooms}} {type} in {location}'
  },
  pageSubtitleZeroBedroom: {
    id: `${scope}.page.subtitle.zeroBedroom`,
    defaultMessage: '{headline}, {type} in {location}'
  }
};

export default defineMessages({
  pageTitle: {
    id: `${scope}.page.title`,
    defaultMessage: '{headline}'
  },
  pageSubtitle: {
    id: `${scope}.page.subtitle`,
    defaultMessage: '{headline}, {bedrooms, plural, =0 {} one {1 bedroom} other {{bedrooms} bedrooms}} {type} in {location}'
  },
  pageSubtitleZeroBedroom: {
    id: `${scope}.page.subtitle.zeroBedroom`,
    defaultMessage: '{headline}, {type} in {location}'
  }
});
