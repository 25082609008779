import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyRates';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Rates',
  },
  seasonTitle: {
    id: `${scope}.season.title`,
    defaultMessage: 'Season',
  },
  rateTitle: {
    id: `${scope}.rate.title`,
    defaultMessage: 'Rate',
  },
  minStayTitle: {
    id: `${scope}.minStay.title`,
    defaultMessage: 'Min Stay',
  },
  minStayPeriod: {
    id: `${scope}.minStay.period`,
    defaultMessage: '{itemCount, plural, =0 {1 day} one {1 day} other {{itemCount} days}}',
  },
  nightlyLabel: {
    id: `${scope}.nightly.label`,
    defaultMessage: 'night',
  },
  weeklyLabel: {
    id: `${scope}.weekly.label`,
    defaultMessage: 'week',
  }
});
