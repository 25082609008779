import React from 'react';
import {compose} from 'redux';
import {createSelector} from 'reselect';
import PropertyAddress from '@rentivo/gatsby-core/src/components/property/PropertyAddress/component';
import {withProperty} from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
    selectPropertyAddress,
    selectPropertyAddressCity,
    selectPropertyAddressCountryCode,
    selectPropertyAddressState
} from '@rentivo/gatsby-core/src/selectors/property';

const propertySelector = (state) => createSelector(
    selectPropertyAddress,
    selectPropertyAddressCity,
    selectPropertyAddressState,
    selectPropertyAddressCountryCode,
    (fullAddress, city, stateProvince, countryISO2) => ({
        fullAddress,
        city,
        stateProvince,
        countryISO2
    })
)(state);

const enhance = compose(
    withProperty(propertySelector)
);

PropertyAddress.defaultProps = {
    className: '',
    styleProps: {},
    showFullAddress: false,
};

export default enhance(PropertyAddress);
