import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyIncluded';

export default defineMessages({
  notIncludedTitle: {
    id: `${scope}.notIncluded.title`,
    defaultMessage: 'What\'s not included?',
  },
  includedTitle: {
    id: `${scope}.included.title`,
    defaultMessage: 'What\'s included?',
  }
});
