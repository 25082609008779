import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyHeader from '@rentivo/gatsby-core/src/components/property/PropertyHeader/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  makeSelectPropertyHeadline,
  makeSelectPropertyLocation,
  selectPropertyAddressCity,
  selectPropertyAddressCountryCode,
  selectPropertyAddressState,
  selectPropertyListingBathrooms,
  selectPropertyListingBedrooms,
  selectPropertyListingMaxOccupancy, selectPropertyListingMaxPets,
  selectPropertyListingSleeps, selectPropertyListingType, selectPropertyMedia,
  selectPropertyName, selectPropertyPhotoFeaturedImageFromMedia, selectPropertyHeadline
} from '@rentivo/gatsby-core/src/selectors/property';
import {
  selectPropertyHeadlineShowSubtitleConfig,
  selectSiteContentVariablesConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { connect } from 'react-redux';
import { selectLang } from '../../../containers/LanguageProvider/selectors';


const propertySelector = (state, { lang }) => createSelector(
  selectPropertyName,
  selectPropertyMedia,
  selectPropertyAddressCity,
  selectPropertyAddressState,
  selectPropertyAddressCountryCode,
  makeSelectPropertyLocation,
  makeSelectPropertyHeadline,
  selectPropertyHeadline,
  selectPropertyListingBathrooms,
  selectPropertyListingBedrooms,
  selectPropertyListingSleeps,
  selectPropertyListingMaxOccupancy,
  selectPropertyListingMaxPets,
  selectPropertyListingType,
  (name, media, city, state, countryCode, location, headline, headlines, bathrooms, bedrooms, sleeps, maxOccupancy, maxPets, type) => ({
    headline, // TODO: Get headline via lang
    image: selectPropertyPhotoFeaturedImageFromMedia(media),
    extraVars: {
      name,
      city,
      state,
      countryCode,
      headline,
      location,
      bathrooms,
      bedrooms,
      sleeps,
      maxOccupancy,
      maxPets,
      type
    }
  })
)(state);

const mapStateToProps = createSelector(
  selectSiteContentVariablesConfig,
  selectPropertyHeadlineShowSubtitleConfig,
  selectLang,
  (siteVariables, showSubtitle, lang) => ({
    siteVariables,
    showSubtitle,
    lang
  })
);

const enhance = compose(
  connect(mapStateToProps),
  withProperty(propertySelector),
);


PropertyHeader.defaultProps = {
  className: '',
  styleProps: {},
  wrapper: ({children}) => children
};

export default enhance(PropertyHeader);
