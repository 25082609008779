import { defineMessages } from 'react-intl';

export const scope = 'components.property.BlockImageGallery';

export default defineMessages({
  backButtonText: {
    id: `${scope}.backButton.text`,
    defaultMessage: 'Back to search',
  }
});
