import React from 'react';
import {
  StyledPropertyPolicies
} from '@rentivo/gatsby-core/src/components/property/PropertyPolicies/styles';
import TruncateText from '@rentivo/gatsby-core/src/components/ui/TruncateText';
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle';

const PropertyPoliciesComponent = ({wrapper: Wrapper, policies, showTitle, title, lines, className}) => {
  if(!policies.length) return null;

  return (
    <Wrapper>
      <StyledPropertyPolicies className={className}>
        {policies.map((policy, i) => (
          <React.Fragment key={`policy-${i}`}>
            {policy.title && <PropertyComponentTitle>{policy.title}</PropertyComponentTitle>}
            <TruncateText
              lines={lines}
              text={policy.content}
            />
          </React.Fragment>
        ))}
      </StyledPropertyPolicies>
    </Wrapper>
  );
};

export default PropertyPoliciesComponent;
