import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyTagsShort';

export default defineMessages({
  popoverTitle: {
    id: `${scope}.popover.title`,
    defaultMessage: 'Tags',
  },
  popoverButtonLabel: {
    id: `${scope}.popover.button.label`,
    defaultMessage: '{firstTag} ({tagsCount} more)',
  }
});
