import { defineMessages } from 'react-intl';

export const scope = 'components.property.BlockImageGallery';

export default defineMessages({
  viewGalleryButton: {
    id: `${scope}.viewGallery.button`,
    defaultMessage: 'View gallery ({photos} photos)',
  }
});
