import { defineMessages } from 'react-intl';

export const scope = 'components.ui.TruncateText';

export default defineMessages({
  showMoreLabel: {
    id: `${scope}.showMore.label`,
    defaultMessage: 'Show more'
  },
  showLessLabel: {
    id: `${scope}.showLess.label`,
    defaultMessage: 'Show less'
  }
});
