import React from 'react';
import {
  StyledPropertyIncluded
} from '@rentivo/gatsby-core/src/components/property/PropertyIncluded/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyIncluded/messages';
import TruncateText from '@rentivo/gatsby-core/src/components/ui/TruncateText';
import { Row, Col } from '@rentivo/gatsby-core/src/components/ui/Row';
import { Heading } from '@chakra-ui/react';

const PropertyIncludedComponent = ({wrapper: Wrapper, included, notIncluded, showTitles, lines, includedTitle, notIncludedTitle, className}) => {
  if(!included && !notIncluded) return null;

  const cols = (included && notIncluded) ? 2 : 1;

  return (
    <Wrapper>
      <StyledPropertyIncluded className={className}>
        <Row>
          {included && (
            <Col colSpan={{base: 24, lg: 24 / cols}} mb={{base: 6, lg: 0}}>
              {showTitles && <Heading as="h2" mb={3}>{includedTitle ? includedTitle : <FormattedMessage {...messages.includedTitle}/>}</Heading>}
              <TruncateText
                lines={lines}
                text={included}
              />
            </Col>
          )}
          {notIncluded && (
            <Col colSpan={{base: 24, lg: 24 / cols}}>
              {showTitles && <Heading as="h2" mb={3}>{notIncludedTitle ? notIncludedTitle : <FormattedMessage {...messages.notIncludedTitle}/>}</Heading>}
              <TruncateText
                lines={lines}
                text={notIncluded}
              />
            </Col>
          )}
        </Row>
      </StyledPropertyIncluded>
    </Wrapper>
  );
};

export default PropertyIncludedComponent;
