import TruncateText from '@rentivo/gatsby-core/src/components/ui/TruncateText/component';

TruncateText.defaultProps = {
  lines: 5,
  text: null,
  className: '',
  showExpand: true
};

export default TruncateText;
