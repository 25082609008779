import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyRooms';

export default defineMessages({
  bedroomsTitle: {
    id: `${scope}.bedrooms.title`,
    defaultMessage: 'Bedrooms',
  },
  bathroomsTitle: {
    id: `${scope}.bathrooms.title`,
    defaultMessage: 'Bathrooms',
  },
  sharedSpaceLabel: {
    id: `${scope}.sharedSpace.label`,
    defaultMessage: 'Shared space',
  }
});
