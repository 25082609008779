import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyDescription';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Description',
  }
});
