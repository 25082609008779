import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyHead';

export const messagesObject = {
  seoTitle: {
    id: `${scope}.seo.title`,
    defaultMessage: '{headline} {sep} {siteTitle}'
  },
  seoDesc: {
    id: `${scope}.seo.desc`,
    defaultMessage: '{headline}, {bedrooms} bedroom {type} in {location}. Book now for the best price.'
  }
};

export default defineMessages({
  seoTitle: {
    id: `${scope}.seo.title`,
    defaultMessage: '{headline} {sep} {siteTitle}'
  },
  seoDesc: {
    id: `${scope}.seo.desc`,
    defaultMessage: '{headline}, {bedrooms} bedroom {type} in {location}. Book now for the best price.'
  }
});
