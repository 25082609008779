import styled from '@emotion/styled';

export const StyledPropertyIncluded = styled.div`
  .ant-col {
    
    @media(max-width: ${props => props.theme['screen-xl']}) {
      margin-bottom: ${props => props.theme['padding-lg']};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
