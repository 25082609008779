import React from 'react';
import { useLocation } from '@reach/router';
import { Button, LocaleLink } from '@rentivo/gatsby-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons/faLongArrowLeft';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/BackButton/messages';

const BackButtonComponent = ({children, useButton, text, openInNewTab, ...rest}) => {
  if(openInNewTab) return null;
  const { state } = useLocation();
  const { prevPath, prevUrl } = state || {};
  if(!prevPath) return null;
  const content = <>{text ? text : <FormattedMessage {...messages.backButtonText}/>}</>;
  return (
    <LocaleLink to={(prevUrl) ? prevUrl : prevPath}>
      {useButton ? <Button {...rest} leftIcon={<FontAwesomeIcon icon={faLongArrowLeft}/>}>{content}</Button> : <a {...rest}>{content}</a>}
    </LocaleLink>
  );
};

export default BackButtonComponent;
