import React from 'react'
import {
  StyledPropertyFeatures,
  StyledPropertyFeatureSet,
  StyledPropertyFeatureSetFeature,
  StyledPropertyFeatureSetFeatureInfo,
} from '@rentivo/gatsby-core/src/components/property/PropertyFeatures/styles'
import { FormattedMessage } from 'react-intl'
import dateReviewMessages from '@rentivo/gatsby-core/src/components/checkout/DatesReview/messages'
import messages, {
  categoryTitleMessages,
} from '@rentivo/gatsby-core/src/components/property/PropertyFeatures/messages'
import { getFeatureCategoryTranslationKey } from '@rentivo/gatsby-core/src/components/property/PropertyFeatures/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LycanFormattedMessage from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle'
import { Heading } from '@chakra-ui/react'
import { Row, Col } from '@rentivo/gatsby-core/src/components/ui/Row'

const PropertyFeaturesComponent = ({
  wrapper: Wrapper,
  featureSets,
  features,
  showTitle,
  title,
  className,
  checkInStartTime,
  checkInEndTime,
  checkOutTime,
}) => {
  if (!features || !features.length) return null

  return (
    <Wrapper>
      <StyledPropertyFeatures className={className}>
        {showTitle && (
          <PropertyComponentTitle>
            {title ? title : <FormattedMessage {...messages.title} />}
          </PropertyComponentTitle>
        )}

        {featureSets.map((fs, i) => (
          <StyledPropertyFeatureSet key={`${fs.category}-${i}`}>
            {fs.features && fs.category && fs.features.length > 0 && (
              <>
                {categoryTitleMessages.includes(
                  getFeatureCategoryTranslationKey(fs.category)
                ) && (
                  <Heading as="h3" fontSize="lg" mb={1}>
                    <FormattedMessage
                      {...messages[
                        getFeatureCategoryTranslationKey(fs.category)
                      ]}
                    />
                  </Heading>
                )}
                <Row>
                  {fs.features.map((feature, i) => (
                    <Col
                      key={`${feature}-${i}`}
                      colSpan={{ base: 24, md: 12, lg: 8 }}
                    >
                      <StyledPropertyFeatureSetFeature
                        data-feature-type={`containers.LycanMessages.${feature.type}`}
                      >
                        <FontAwesomeIcon icon={faCheck} />{' '}
                        <LycanFormattedMessage message={feature.type} />
                        {feature.description && (
                          <StyledPropertyFeatureSetFeatureInfo
                            title={feature.description}
                          />
                        )}
                      </StyledPropertyFeatureSetFeature>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </StyledPropertyFeatureSet>
        ))}

        {checkInStartTime && (
          <StyledPropertyFeatureSet>
            <Heading as="h3" fontSize="lg" mb={1}>
              <FormattedMessage
                {...dateReviewMessages.checkinCheckoutTimesTitle}
              />
            </Heading>
            <Row>
              <Col colSpan={{ base: 24 }}>
                <FontAwesomeIcon icon={faCheck} />{' '}
                <FormattedMessage
                  {...dateReviewMessages.checkinStartTimeLabel}
                  values={{ time: checkInStartTime }}
                />
                &nbsp;
                {checkInEndTime && (
                  <FormattedMessage
                    {...dateReviewMessages.checkinEndTimeLabel}
                    values={{ time: checkInEndTime }}
                  />
                )}
              </Col>
              {checkOutTime && (
                <Col colSpan={{ base: 24 }}>
                  <FontAwesomeIcon icon={faCheck} />{' '}
                  <FormattedMessage
                    {...dateReviewMessages.checkoutTimeLabel}
                    values={{ time: checkOutTime }}
                  />
                </Col>
              )}
            </Row>
          </StyledPropertyFeatureSet>
        )}
      </StyledPropertyFeatures>
    </Wrapper>
  )
}

export default PropertyFeaturesComponent
