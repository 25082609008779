import React from 'react';
import {
  StyledPropertyRates
} from '@rentivo/gatsby-core/src/components/property/PropertyRates/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyRates/messages';
//import { Table } from 'antd';
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle';

const columns = [
  {
    title: <FormattedMessage {...messages.seasonTitle}/>,
    dataIndex: 'season',
    key: 'season'
  },
  {
    title: <FormattedMessage {...messages.rateTitle}/>,
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: <FormattedMessage {...messages.minStayTitle}/>,
    dataIndex: 'minStay',
    key: 'minStay',
  },
];

const PropertyRatesComponent = ({wrapper: Wrapper, data, showTitle, title, className}) => {
  return null; // TODO: Can't implement into we have some working rate...

  if(!data.length) return null;


  return (
    <Wrapper>
      <StyledPropertyRates className={className}>
        {showTitle && <PropertyComponentTitle>{title ? title : <FormattedMessage {...messages.title}/>}</PropertyComponentTitle>}

      </StyledPropertyRates>
    </Wrapper>
  );
};

// <Table columns={columns} dataSource={data} />

export default PropertyRatesComponent;
