import {
  selectPropertyMediaByCategoryFromMedia,
  selectPropertyPhotosFromToFromMedia
} from '@rentivo/gatsby-core/src/selectors/property';
import galleryImageCdnProps from '@rentivo/gatsby-core/src/components/property/BlockImageGallery/galleryImage.cdn';
import staticImageCdnProps from '@rentivo/gatsby-core/src/components/property/BlockImageGallery/staticImage.cdn';
import { getClosestContentObjByLocale } from '@rentivo/gatsby-core/src/utils/getters';
import {FLOORPLAN, PHOTO} from "../../../constants/lycanConstants";

export const selectStaticPhotos = (media, from, to, getCdnImageUrl, headline, lang = 'en', defaultLang = 'en') => {
  const images = selectPropertyPhotosFromToFromMedia(media, from, to);
  return images.map((m, i) => {
    const cap = m.description && m.description.length > 0 ? getClosestContentObjByLocale(m.description, lang) : null;
    const alt = (cap && cap.title) ? cap.title : `${headline} #${i + 1}`;

    return {
      source: (i === 0) ? getCdnImageUrl(m.uri, galleryImageCdnProps) : getCdnImageUrl(m.uri, staticImageCdnProps),
      alt
    }
  });
};

export const selectGalleryPhotos = (media, getCdnImageUrl, headline, lang = 'en', defaultLang = 'en') => {
  const images = selectPropertyMediaByCategoryFromMedia(media, [PHOTO, FLOORPLAN]);
  return images.map((m, i) => {
    const cap = m.description && m.description.length > 0 ? getClosestContentObjByLocale(m.description, lang) : null;
    const alt = `${headline} Photo - ${i + 1}`;
    const caption = (cap && cap.title) ? cap.title : alt;
    return {
      caption,
      source: getCdnImageUrl(m.uri, galleryImageCdnProps),
    };
  });
};

