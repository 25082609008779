import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyListingMeta from '@rentivo/gatsby-core/src/components/property/PropertyListingMeta/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  makeSelectPropertyIsPetFriendly, selectPropertyFeatures, selectPropertyIsPetFriendlyFromFeatures,
  selectPropertyListingBathrooms,
  selectPropertyListingBedrooms,
  selectPropertyListingMaxOccupancy,
  selectPropertyListingMaxPets,
  selectPropertyListingOverflow,
  selectPropertyListingSleeps, selectPropertyListingType,
} from '@rentivo/gatsby-core/src/selectors/property';

const propertySelector = createSelector(
  selectPropertyListingBathrooms,
  selectPropertyListingBedrooms,
  selectPropertyListingSleeps,
  selectPropertyListingMaxOccupancy,
  selectPropertyListingMaxPets,
  selectPropertyListingType,
  selectPropertyFeatures,
  (bathrooms, bedrooms, sleeps, maxOccupancy, maxPets, type, features) => {
    const isPetFriendly = selectPropertyIsPetFriendlyFromFeatures(features);
    return {
      bathrooms,
      bedrooms,
      sleeps,
      maxOccupancy,
      overflow: selectPropertyListingOverflow(sleeps, maxOccupancy),
      maxPets,
      type,
      isPetFriendly
    };
  }
);

const enhance = compose(
  withProperty(propertySelector),
);

PropertyListingMeta.defaultProps = {
  showDetail: false,
  showLabels: false,
  className: ''
};

export default enhance(PropertyListingMeta);
