import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyFeatures';

export const categoryTitleMessages = ['featuresAmenitiesCategoryTitle', 'featuresGeneralCategoryTitle', 'featuresSuitabilityCategoryTitle', 'featuresSafetyCategoryTitle', 'featuresDistanceCategoryTitle'];

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Features',
  },
  featuresAmenitiesCategoryTitle: {
    id: `${scope}.featureAmenitiesCategoryTitle`,
    defaultMessage: 'Amenities',
  },
  featuresGeneralCategoryTitle: {
    id: `${scope}.featuresGeneralCategoryTitle`,
    defaultMessage: 'General',
  },
  featuresSuitabilityCategoryTitle: {
    id: `${scope}.featuresSuitabilityCategoryTitle`,
    defaultMessage: 'Suitability',
  },
  featuresSafetyCategoryTitle: {
    id: `${scope}.featuresSafetyCategoryTitle`,
    defaultMessage: 'Safety',
  },
  featuresDistanceCategoryTitle: {
    id: `${scope}.featuresDistanceCategoryTitle`,
    defaultMessage: 'Distance',
  },
});
